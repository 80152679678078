import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { options } from "../components/options"
import { Title } from "../components/styles"

const Video = loadable(() => import("../components/video"), {
  fallback: <div></div>,
})

const About = ({ data }) => {
  const {
    about: { title, image, video, text },
  } = data

  return (
    <Layout>
      <Seo title={title} />
      <main>
        <Title>{title}</Title>
        {video ? (
          <Video title={title} url={video} />
        ) : (
          image && (
            <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
          )
        )}
        <article>{text && renderRichText(text, options)}</article>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    about: contentfulUber(node_locale: { eq: "de" }, title: { ne: "A_PH" }) {
      title
      image {
        id
        title
        gatsbyImageData(
          formats: [AUTO, WEBP]
          width: 880
          placeholder: DOMINANT_COLOR
        )
      }
      video
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              formats: [AUTO, WEBP]
              width: 880
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  }
`

export default About
